import axios from 'axios';

const state = {
    data: [],
    orderables: [{
            title: 'Nome',
            name: 'nome'
        },
        {
            title: 'E-mail',
            name: 'email'
        },
        {
            title: 'Confirmado',
            name: 'status'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Pre-Cadastro',
        filters: [{
                title: 'Nome',
                name: 'nome',
                type: 'string'
            },
            {
                title: 'E-mail',
                name: 'email',
                type: 'string'
            },
            {
                title: 'Telefone',
                name: 'telefone',
                type: 'string'
            },
            {
                title: 'Confirmado',
                name: 'status',
                type: 'boolean'
            },
            {
                title: 'Cadastrado em',
                name: 'created_at',
                type: 'datetime'
            },
        ]
    }]
};

const mutations = {
    SET_PRE_CADASTRO: (state, payload) => {
        state.data = payload;
    }
};

const actions = {
    setPreCadastro: (context, payload) => {
        context.commit('SET_PRE_CADASTRO', payload);
    },
    getPreCadastroById: (context, payload) => {
        return axios.get(`/api/pre-cadastro/${payload}/detalhes`).then(response => {
            return response;
        }).catch(error => {
            return error;
        });
    },
    getPreCadastroPublico: (context, payload) => {
        return axios.get(`/api/confirmar-email/${payload}`).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    cadastrarOffice: (context, payload) => {
        let token = payload.tokenId;
        delete payload.tokenId;
        let promise = new Promise((resolve, reject) => {
            axios.post(`/api/confirmar-plano/${token}`, payload).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
    reativarDocx: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.post('/api/empresa/adquirir-plano', payload).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
    adquirirAdicional: (context, payload) => {
        return axios.post('/api/empresa/adquirir-modulos', payload).then(response => {
            return response.data;
        }).catch(error => {
            return error.response.data;
        });
    },
    resendMailPreCadastro: (context, payload) => {
        return axios.post(`/api/pre-cadastro/${payload}/reenviar-email`).then(response => {
            return response.data;
        }).catch(error => {
            return error.response.data;
        });
    },
    cadastroPublico: (context, payload) => {
        return axios.post('/api/cadastro-publico', payload).then(response => {
            return response.data;
        }).catch(error => {
            return error.response.data;
        });
    },
};
export default {
    state,
    mutations,
    actions
};