import axios from 'axios';

const state = {
  data: [],
  deleted: [],
  revenda: [],
  orderables: [{
      title: 'Descrição',
      name: 'fin_descricao'
    },
    {
      title: 'Emissão',
      name: 'fin_emissao'
    },
    {
      title: 'Pagamento',
      name: 'fin_pagamento'
    },
    {
      title: 'Vencimento',
      name: 'fin_vencimento'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    },
  ],
  orderables: [{
      title: 'Nome',
      name: 'empresa_nome'
    },
    {
      title: 'CNPJ',
      name: 'empresa_documento'
    },
    {
      title: 'Tipo Ambiente',
      name: 'empresa_ambiente'
    },
    {
      title: 'Status',
      name: 'empresa_status'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    }
  ],
  filterGroups: [{
      name: 'Empresa',
      filters: [{
          title: 'Nome',
          name: 'empresa_nome',
          type: 'string'
        },
        {
          title: 'CNPJ',
          name: 'empresa_documento',
          type: 'document'
        },
        {
          title: 'Status',
          name: 'empresa_status',
          type: 'boolean'
        },
        {
          title: 'É revenda?',
          name: 'is_revenda',
          type: 'boolean'
        },
        {
          title: 'Parceiro revenda',
          name: 'id_revenda',
          description: 'empresa_nome',
          type: 'list',
          model: 'revendas',
        },
        {
          title: 'Cadastrado em',
          name: 'created_at',
          type: 'datetime'
        },
      ]
    },
    {
      name: 'Endereço',
      filters: [{
          title: 'Cidade',
          name: 'empresa_cidade',
          type: 'string'
        },
        {
          title: 'Cep',
          name: 'empresa_cep',
          type: 'document'
        }
      ]
    },
    {
      name: 'Plano',
      filters: [{
          title: 'Descrição',
          name: 'plano.config.nome',
          type: 'string'
        },
        {
          title: 'Validade do Plano',
          name: 'plano.validade.nome',
          type: 'string'
        },
        {
          title: 'Vencimento',
          name: 'plano.vencimento_padrao',
          type: 'datetime'
        },
        {
          title: 'É recorrente?',
          name: 'plano.recorrente',
          type: 'boolean'
        },
        {
          title: 'Valor',
          name: 'plano.valor',
          type: 'numeric'
        }
      ]
    }
  ]
};
const mutations = {
  SET_EMPRESAS: (state, payload) => {
    state.data = payload;
  },
  SET_REVENDA: (state, payload) => {
    state.revenda = payload;
  },
  SET_DASHBOARD: (state, payload) => {
    state.dashboard = payload;
  },
  SET_BOLETO_SELECT: (state, payload) => {
    state.boletos = payload;
  },
  DASHBOARD_FERIADO_UPDATE: (state, payload) => {
    if (payload.type === 'increment') {
      state.dashboard.feriados++;
    } else if (payload.type === 'decrement') {
      state.dashboard.feriados--;
    }
  },
  DASHBOARD_PESSOA_UPDATE: (state, payload) => {
    if (payload.type === 'increment') {
      state.dashboard.pessoas++;
    } else if (payload.type === 'decrement') {
      state.dashboard.pessoas--;
    }
  },
  UPDATE_EMPRESA_LIST: (state, payload) => {
    state.data.map((item, index) => {
      if (item.id === payload) {
        state.data[index].empresa_status = state.data[index].empresa_status == 'A' ? 'D' : 'A'
      }
    });
  },
  REFRESH_PDV: (state, payload) => {
    state.pdvs = payload;
  },
  SET_PARAMETROS_INDEX: (state, payload) => {
    state.nota_series = payload.nota_series;
    state.inscricoes_sub = payload.inscricoes_sub;
  },
};

const actions = {
  setEmpresas: (context, payload) => {
    context.commit('SET_EMPRESAS', payload);
  },
  migratePrime: (context, payload) => {
    return axios.post(`/api/empresa/${payload.id}/migrar`, payload.form, payload.config)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error;
      });
  },
  fixMigratePrime: (context, payload) => {
    return axios.post(`/api/empresa/${payload.id}/fix-migrate`, payload.form, payload.config)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error;
      });
  },
  fixStructure: (context, payload) => {
    return axios.put(`/api/empresa/${payload}/fix-structure`)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error;
      });
  },
  storeEmpresa: (context, payload) => {
    return axios.post('/api/empresa/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error;
      });
  },
  updateEmpresa: (context, payload) => {
    return axios.put(`/api/empresa/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getEmpresaById: (context, payload) => {
    return axios.get(`/api/empresa/${payload}/detalhes`).then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  },
  checkCompanyCnpj: (context, payload) => {
    return axios.get(`/api/empresa/cnpj/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  },
  disableCompany: (context, payload) => {
    return axios.put(`/api/empresa/${payload}/disable`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeCertificate: (context, payload) => {
    return axios.post('/api/empresa/certificado', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        return response.data;
      }).catch(error => {
        return error;
      });
  },
  destroyCertificate: () => {
    return axios.delete('/api/empresa/certificado')
      .then(response => {
        return response.data;
      }).catch(error => {
        return error;
      });
  },
  confirmEmail: (context, payload) => {
    return axios.post(`/api/confirmar-email/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  confirmVinculo: (context, payload) => {
    return axios.put(`/api/confirmar-vinculo/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  savePlanoEmpresa: (context, payload) => {
    return axios.put(`/api/empresa/${payload.fk_empresa_id}/plano`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  cancelSignature: (context, payload) => {
    return axios.delete(`/api/empresa/${payload}/cancelar-assinatura`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getEmpresaPlano: (context, payload) => {
    return axios.get(`/api/empresa/${payload}/plano`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getHistoryByEmpresa: (context, payload) => {
    return axios.get(`/api/empresa/${payload.id}/historico?amount=${payload.amount}&page=${payload.page}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  experimentarGratis: (context, payload) => {
    return axios.post('/api/experimentar-gratis', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  generateCompanyToken: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/empresa/${payload}/gerar-token`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  getEmpresaRevenda: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/empresa/revendas`).then(response => {
        context.commit('SET_REVENDA', response.data)
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  getRevenda: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/revenda/${payload}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  getResale: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/revenda/${payload}`).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
};

export default {
  state,
  mutations,
  actions
};