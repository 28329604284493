import axios from 'axios';

const state = {
    data: [],
    orderables: [{
            title: 'Número',
            name: 'numero'
        },
        {
            title: 'Enviado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'SMS',
        filters: [{
                title: 'Número',
                name: 'numero',
                type: 'string'
            },
            {
                title: 'Comprovante',
                name: 'id_envio',
                type: 'string'
            },
            {
                title: 'Enviao em',
                name: 'created_at',
                type: 'datetime'
            }
        ]
    }]
};

const mutations = {
    SET_SMS: (state, payload) => {
        state.data = payload;
    },
};

const actions = {
    setSms: (context, payload) => {
        context.commit('SET_SMS', payload);
    },
    buySmsPackage: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.post('/api/sms/comprar', payload).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
    getApiSmsBalance: () => {
        let promise = new Promise((resolve, reject) => {
            axios.get('/api/sms/saldo-api').then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
    getSMSModulePrice: () => {
        let promise = new Promise((resolve, reject) => {
            axios.get('/api/modulo-sms').then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
};
export default {
    state,
    mutations,
    actions
};