import axios from 'axios';

const state = {
    data: [],
    select: [],
    orderables: [{
            title: 'Nome',
            name: 'nome'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Validade',
        filters: [{
                title: 'Nome',
                name: 'nome',
                type: 'string'
            },
            {
                title: 'Vencimento',
                name: 'vencimento',
                type: 'numeric'
            },
        ]
    }]

};

const mutations = {
    SET_PLANO_VALIDADE: (state, payload) => {
        state.data = payload;
    },
    SET_PLANO_VALIDADE_SELECT: (state, payload) => {
        state.select = payload;
    },
};

const actions = {
    setPlanoValidade: (context, payload) => {
        context.commit('SET_PLANO_VALIDADE', payload);
    },
    storePlanoValidade: (context, payload) => {
        return axios.post('/api/plano-validade/cadastrar', payload)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    updatePlanoValidade: (context, payload) => {
        return axios.put(`/api/plano-validade/${payload.id}/editar`, payload).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getPlanoValidadeById: (context, payload) => {
        return axios.get(`/api/plano-validade/${payload}/detalhes`).then(response => {
            return response;
        }).catch(error => {
            return error;
        });
    },
};
export default {
    state,
    mutations,
    actions
};