import axios from 'axios';

const state = {
  data: [],
  orderables: [{
    title: 'Cadastrado em',
    name: 'created_at'
  }],
  filterGroups: [{
    name: 'Operação',
    filters: [{
        title: 'Operação',
        name: 'event',
        type: 'listCustom',
        data: [{
            value: 'created',
            description: 'Cadastro '
          },
          {
            value: 'updated',
            description: 'Edição'
          },
          {
            value: 'deleted',
            description: 'Exclusão'
          },
          {
            value: 'restored',
            description: 'Recuperacão'
          }
        ],
      },
      {
        title: 'Usuário',
        name: 'user_id',
        description: 'nome',
        type: 'list',
        model: 'users',
      },
      {
        title: 'Tipo de Cadastro',
        name: 'auditable_type',
        type: 'string'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at',
        type: 'datetime'
      }
    ],
  }],
};

const mutations = {
  SET_AUDITORIAS: (state, payload) => {
    state.data = payload;
  }
};

const actions = {
  getAuditoriaById: (context, payload) => {
    return axios.get(`/api/auditoria/${payload}/detalhes`).then(response => {
      return response;
    }).catch(error => {
      return error;
    });
  },
  setAuditorias: (context, payload) => {
    context.commit('SET_AUDITORIAS', payload);
  },
};
export default {
  state,
  mutations,
  actions
};