import axios from 'axios';
import socket from '../../events/socket-connect';

const state = {
  refresh_id: null,
  data: {
    id: null,
    email: '',
    empresa: {},
    permissoes: []
  },
  localSettings: {
    user_language: 'pt_BR',
    user_skin: 'skin-blue-light',
    box: 'box-primary',
    btn: 'btn-primary',
    tab: 'tab-primary',
    nav: 'navbar-primary',
    layout: {},
  },
  select: [],
  index: [],
  orderables: [{
      title: 'Nome',
      name: 'nome'
    },
    {
      title: 'Documento',
      name: 'documento'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    }
  ],
  filterGroups: [{
    name: 'Usuario',
    filters: [{
        title: 'Nome',
        name: 'nome',
        type: 'string'
      },
      {
        title: 'Documento',
        name: 'documento',
        type: 'document'
      },
      {
        title: 'Email',
        name: 'email',
        type: 'string'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at',
        type: 'datetime'
      }
    ]
  }]
};

const mutations = {
  UPDATE_USER_LIST: (state, payload) => {
    let test = state.index.findIndex(x => x.id == payload);
    state.index[test].status = !state.index[test].status;
  },
  SET_USER: (state, payload) => {
    state.data = payload;
    if (!state.data.empresa) {
      state.data.empresa = {}
      state.data.permissions = []
    }
  },
  SET_USER_PERMISSION: (state, payload) => {
    state.data.permissions = payload;
  },
  SET_EMPRESA_NIVEIS: (state, payload) => {
    state.data.empresa.empresa_niveis = payload;
  },
  SET_USER_FOLDERS: (state, payload) => {
    state.data.folders = payload;
  },
  SET_LAYOUT: (state, payload) => {
    state.localSettings.layout.box = `box-${payload}`;
    state.localSettings.layout.btn = `btn-${payload}`;
    state.localSettings.layout.tab = `card-${payload}`;
    state.localSettings.layout.card = `card-${payload}`;
    state.localSettings.layout.nav = `navbar-${payload}`;
    state.localSettings.layout.check = `${payload}`;
    state.localSettings.layout.theme = `${payload}`;
  },
  CHANGE_LANGUAGE: (state, payload) => {
    state.localSettings.user_language = payload;
  },
  CHANGE_USER_SKIN: (state, payload) => {
    state.localSettings.user_skin = payload;
  },
  SET_USER_SELECT: (state, payload) => {
    state.select = payload;
  },
  SET_USERS: (state, payload) => {
    state.index = payload;
  },
  REFRESH_USER_FILES: (state, payload) => {
    state.refresh_id = payload;
  },
};
const actions = {
  getUserData: (context) => {
    return axios.get('/api/auth/user')
      .then((response) => {
        if (response.data.status !== 401) {
          context.commit('SET_USER', response.data);
        }
        return response;
      }).then(response => {
        if (response.data.status !== 401) {
          socket.socketConnect();
        }
      })
      .catch(error => {
        console.log('erro ao pegar dados do usuário', error);
        return error;
      });
  },
  openFolder: (context, payload) => {
    return axios.put(`/api/nivel/${payload}/acessar`).then(response => {
      context.commit('SET_USER', response.data);
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  backFolder: (context, payload) => {
    return axios.put(`/api/nivel/${payload}/voltar`).then(response => {
      context.commit('SET_USER', response.data);
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  goToRoot: (context, payload) => {
    return axios.put(`/api/nivel/cofre`).then(response => {
      context.commit('SET_USER', response.data);
      return response.data;
    }).catch(error => {
      console.log('erro ao pegar dados do usuário', error);
      return error
    });
  },
  socketLogin: () => {
    socket.socketConnect();
  },
  socketLogout: () => {
    socket.socketDisconnect();
  },
  getUserPermission: (context) => {
    axios.get('/api/auth/permission')
      .then((response) => {
        context.commit('SET_USER_PERMISSION', response.data);
      }).catch(error => {
        console.log(error);
      });
  },
  getUserSelect: (context) => {
    axios.get('/api/empresa/usuarios')
      .then((response) => {
        context.commit('SET_USER_SELECT', response.data);
      }).catch(error => {
        console.log(error);
      });
  },
  checkUniqueEmail: (context, payload) => {
    return axios.get(`/api/username/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  previewNotification: (context, payload) => {
    return axios.put(`/api/usuario/notification/${payload}/show`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  previewAllNotification: () => {
    return axios.put('/api/usuario/notifications/show').then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  },
  deleteNotificationById: (context, payload) => {
    return axios.delete(`/api/usuario/notification/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  deleteAllNotifications: () => {
    return axios.delete('/api/usuario/notifications').then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  changeLanguage: (context, payload) => {
    return axios.put(`/api/usuario/change-language/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  changeUserSkin: (context, payload) => {
    axios.put(`/api/usuario/change-skin/${payload}`).then(() => {}).catch(error => {
      console.log(error.response.data);
    });
  },
  changeProfilePhoto: (context, payload) => {
    return axios.post('/api/usuario/photo', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeUserEmail: (context, payload) => {
    return axios.post('/api/usuario/email', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  cancelInvite: (context, payload) => {
    return axios.delete(`/api/invite/${payload}/cancelar`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  updateUserEmail: (context, payload) => {
    return axios.put(`/api/usuario/email/${payload.id}`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  destroyUserEmail: (context, payload) => {
    return axios.delete(`/api/usuario/email/${payload.id}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  removeUser: (context, payload) => {
    return axios.delete(`/api/usuario/${payload}/remover-vinculo`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeUserPhone: (context, payload) => {
    return axios.post('/api/usuario/phone', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  updateUserPhone: (context, payload) => {
    return axios.put(`/api/usuario/phone/${payload.id}`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  destroyUserPhone: (context, payload) => {
    return axios.delete(`/api/usuario/phone/${payload.id}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeUserAddress: (context, payload) => {
    return axios.post('/api/usuario/address', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  updateUserAddress: (context, payload) => {
    return axios.put(`/api/usuario/address/${payload.id}`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  destroyUserAddress: (context, payload) => {
    return axios.delete(`/api/usuario/address/${payload.id}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  changePassword: (context, payload) => {
    return axios.put('/api/usuario/password/', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  updateUser: (context, payload) => {
    return axios.put('/api/user', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeUser: (context, payload) => {
    return axios.post('/api/usuario/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  updateUserById: (context, payload) => {
    return axios.put(`/api/usuario/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getUserById: (context, payload) => {
    return axios.get(`/api/usuario/${payload}/detalhes`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getUserEdit: (context, payload) => {
    return axios.get(`/api/usuario/${payload}/editar`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getUserCompanies: (context, payload) => {
    return axios.get(`/api/usuario/${payload}/companies`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getUserPermissions: (context, payload) => {
    return axios.get(`/api/usuario/${payload}/permissions`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  searchUser: (context, payload) => {
    return axios.get(`/api/users/search/${payload}`)
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error);
      });
  },
  setUsers: (context, payload) => {
    context.commit('SET_USERS', payload);
  },
  changeCompanyLink: (context, payload) => {
    return axios.put('/api/usuario/company', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  changeUserPermissions: (context, payload) => {
    return axios.put(`/api/usuario/${payload.id}/permission`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  disableUser: (context, payload) => {
    return axios.put(`/api/usuario/${payload}/disable`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  uploadFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post('/api/file/upload', payload.req, payload.config)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  moveFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put('/api/files/move', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  renameFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put(`/api/file/${payload.id}/rename`, payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  destroyFile: (context, payload) => {
    return axios.delete(`/api/file/${payload}/deletar`)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  cancelFile: (context, payload) => {
    return axios.put(`/api/arquivo/${payload}/cancelar`)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  denyFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
     axios.put(`/api/arquivo/${payload.id}/negar`, payload)
      .then(response => {
         resolve(response.data);
      }).catch(error => {
         reject(error.response.data);
      });
    });
    return promise;
  },
  denyFilePublic: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
     axios.put(`/api/arquivo/${payload.id}/negar-publico`, payload)
      .then(response => {
         resolve(response.data);
      }).catch(error => {
         reject(error.response.data);
      });
    });
    return promise;
  },
  removeSignature: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.delete(`/api/arquivo/${payload.arquivo}/assinatura/${payload.assinatura}/deletar`)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
      });
    return promise;
  },
  getFile: (context, payload) => {
    return axios({
      url: `/api/file/${payload}/url`,
      method: 'GET',
      // responseType: 'json'
      responseType: 'json'
    }).then((response) => {
     /*  const file = new Blob(
        [response.data], {
          type: 'application/pdf'
        });
      const url = window.URL.createObjectURL(file);
      return url; */
      return response.data.url;
    });
  },
  getFileEvents: (context, payload) => {
    return axios.get(`/api/file/${payload}/events`)
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error);
      });
  },
  storeUserPasta: (context, payload) => {
    return axios.post('/api/pasta/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  ordenarUserPasta: (context, payload) => {
    return axios.put('/api/pasta/ordenar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  deleteUserFolder: (context, payload) => {
    return axios.put(`/api/pasta/${payload.id}/deletar`, payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  updateUserPasta: (context, payload) => {
    return axios.put(`/api/pasta/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getRegisterData: (context, payload) => {
    return axios.get(`/api/cadastro-usuario/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getSignatarioData: (context, payload) => {
    return axios.get(`/api/cadastro-signatario/${payload}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  storeSignatario: (context, payload) => {
    return axios.post('/api/signatario/cadastrar', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  confirmStoreUser: (context, payload) => {
    return axios.post('/api/usuario/confirmar', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getEula: (context, payload) => {
    return axios.get('/api/eula').then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  generateGoogleSecret: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
     axios.get('/api/auth/otp')
      .then(response => {
         resolve(response.data);
      }).catch(error => {
         reject(error.response.data);
      });
    });
    return promise;
  },
  changeTwoFactory: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
     axios.put('/api/auth/change-two-factory', payload)
      .then(response => {
         resolve(response.data);
      }).catch(error => {
         reject(error.response.data);
      });
    });
    return promise;
  },
  getSystemSettings: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
     axios.get('/api/system-settings')
      .then(response => {
         resolve(response.data);
      }).catch(error => {
         reject(error.response.data);
      });
    });
    return promise;
  },
  saveSystemSettings: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
     axios.put('/api/system-settings', payload)
      .then(response => {
         resolve(response.data);
      }).catch(error => {
         reject(error.response.data);
      });
    });
    return promise;
  },
  storeDevice: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post('/api/whatsapp-device', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  getDeviceStatus: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/whatsapp-status`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  disconectDevice: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.delete(`/api/whatsapp-device`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  getDeviceQrcode: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/whatsapp-scan`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  storeDeviceClient: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post('/api/client/whatsapp-device', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  getDeviceStatusClient: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/client/whatsapp-status`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  disconectDeviceClient: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.delete(`/api/client/whatsapp-device`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  getDeviceClientQrcode: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/client/whatsapp-scan`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
};

export default {
  state,
  mutations,
  actions
};