require('./assets/sass/app.scss');
import $ from 'jquery';
import _ from 'lodash';
import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueRouter from 'vue-router';
import store from './store';
import Auth from './store/modules/auth';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueAnalytics from 'vue-analytics';
import {
  mask
} from 'vue-the-mask';
import money from 'v-money';
import PrettyCheckbox from 'pretty-checkbox-vue';
import myUpload from 'vue-image-crop-upload';
import VueChartkick from 'vue-chartkick';
import Chart from 'chart.js';
import crud from './functions/crud';
import filterCrud from './functions/filterCrud';
import common from './functions/common';
import service from './functions/apiServices';
import router from './config/router';
import i18n from './lang';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import moment from 'moment';
import VueNestable from 'vue-nestable';
import InfiniteLoading from 'vue-infinite-loading';
import wysiwyg from "vue-wysiwyg";
import {
  VueReCaptcha
} from "vue-recaptcha-v3";
import AnimatedNumber from "animated-number-vue";
import OtpInput from "@bachdgvn/vue-otp-input";

import VueApexCharts from 'vue-apexcharts';

//import * as Sentry from '@sentry/browser';
//import * as Integrations from '@sentry/integrations';

// Globals
window.io = require('socket.io-client');
window.jQuery = $;
window.$ = $;
window.moment = moment;
global.jQuery = $;
window._ = _;
window.service = service;
require('bootstrap');
require('admin-lte');
// require('./plugins/loader');
require('./config/interceptors');
require('./config/guards');
console.log(process.env.VUE_APP_BASE_URL);
/* 
// Debug log
Sentry.init({
  //dsn: 'https://1e2a6a0f136347f4886258202c925125@sentry.io/1545560',
  integrations: [new Integrations.Vue({
    Vue,
    attachProps: true
  })],
}); */

//axios.defaults.baseURL = host;
Vue.component('vue-slider', () => import('vue-slider-component'));
Vue.component('downloadExcel', () => import('vue-json-excel'));
Vue.component('Filterable', () => import('./components/Filterable.vue'));
Vue.component('AdvancedFilter', () => import('./components/advanced-filter/Filter.vue'));
Vue.component('pagination', () => import('./components/Pagination.vue'));
Vue.component('ImportData', () => import('./views/partials/Import'));
Vue.component('ShowData', () => import('./views/partials/ShowData'));
Vue.component('AutoComplete', () => import('./components/AutoComplete.vue'));
Vue.component('Required', () => import('./components/Required.vue'));
Vue.component('SelectOrigin', () => import('./components/SelectOrigin.vue'));
Vue.component('FolderExplorer', () => import('./components/folder-explorer/sl-vue-tree.vue'));
Vue.component('VueContext', () => import('vue-context'));
Vue.component('Popover', () => import('./components/Popover.vue'));
Vue.component('AnimatedNumber', AnimatedNumber);
Vue.component(VueNumberInput.name, VueNumberInput);
Vue.component('apexchart', VueApexCharts);
Vue.use(wysiwyg, {});
Vue.use(VueSweetalert2);
Vue.use(VueChartkick, {
  adapter: Chart
});
Vue.use(InfiniteLoading);
Vue.use(VueNestable);
Vue.use(myUpload);
Vue.use(PrettyCheckbox);
Vue.directive('mask', mask);
Vue.use(money, {
  precision: 2,
  prefix: 'R$ ',
  decimal: ',',
  thousands: '.',
});
Vue.component("v-otp-input", OtpInput);
Vue.use(VueRouter);
Vue.use(Auth);
if (process.env.VUE_APP_GANALYTICS_ID) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GANALYTICS_ID,
    router,
    autoTracking: {
      pageviewTemplate (route) {
        return {
          page: route.name,
          title: document.title,
          location: route.path
        }
      },
      skipSamePath: true,
      transformQueryString: false,
      shouldRouterUpdate(to, from) {
        // Here I'm allowing tracking only when
        // next route path is not the same as the previous
        return to.path !== from.path;
      }
    }
  });
}

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GRECAPCHA_KEY,
  autoHideBadge: true
});

Vue.directive('numericOnly', {
  bind(el, binding, vnode) {
    // console.log(el, binding);
    // this two prevent from copy&paste non-number text, including "e".
    // need to have both together to take effect.

    el.type = 'number';
    el.addEventListener('input', (e) => {
      // console.log('input', e);
      // console.log(el.validity);
      //  alert(el.value);
      // return el.validity.valid || (el.value = '');
    });

    // this prevents from typing non-number text, including "e".
    el.addEventListener('keypress', (e) => {
      let charCode = (e.which) ? e.which : e.keyCode;
      //charCode = charCode === 44 ? 46 : charCode;
      if ((charCode >= 48 && charCode <= 57) || [44, 46].includes(charCode)) {
        return true;
      } else {
        e.preventDefault();
      }
    });
  }
});


Vue.config.productionTip = false
Vue.mixin(common);
Vue.mixin(crud);
Vue.mixin(filterCrud);
var app = new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  methods: {
    getMessage: function (key) {
      return this.$t(key);
    }
  }
}).$mount('#app')

/* var app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  methods: {
    getMessage: function (key) {
      return this.$t(key);
    }
  }
}); */

window.trans = app.getMessage;

window.Swal = app.$swal;

window.toastr = ({ icon, title, timer }) => Swal.fire({
  toast: true,
  position: 'top-end',
  showCloseButton: true,
  showConfirmButton: false,
  showCancelButton: false,
  html: null,
  timer: timer || 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
  icon,
  title,
});


axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.prototype.$http = axios;

Object.defineProperties(Vue.prototype, {
  router: {
    get() {
      return router;
    }
  }
});