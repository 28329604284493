import axios from 'axios';

const state = {
    data: [],
    orderables: [{
            title: 'Nome',
            name: 'nome'
        },
        {
            title: 'Documento',
            name: 'documento'
        },
        {
            title: 'E-mail',
            name: 'email'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Signatário',
        filters: [{
                title: 'Nome',
                name: 'nome',
                type: 'string'
            },
            {
                title: 'Documento',
                name: 'documento',
                type: 'document'
            },
            {
                title: 'E-mail',
                name: 'email',
                type: 'string'
            },
            {
                title: 'Telefone',
                name: 'telefone',
                type: 'string'
            },
            {
                title: 'Cadastrado em',
                name: 'created_at',
                type: 'datetime'
            }
        ]
    }]
};

const mutations = {
    SET_SIGNATARIOS: (state, payload) => {
        state.data = payload;
    },
};

const actions = {
    setSignatarios: (context, payload) => {
        context.commit('SET_SIGNATARIOS', payload);
    },
    getAllSignatariosSelect: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.get('/api/signatarios/select')
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
        });
        return promise;
    },
    saveGrupoSignatario: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.post('/api/grupo-signatarios', payload).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
    destroyGroup: (context, payload) => {
        let promise = new Promise((resolve, reject) => {
            axios.delete(`/api/grupo-signatario/${payload}/deletar`).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.response.data);
            });
        });
        return promise;
    },
};
export default {
    state,
    mutations,
    actions
};