const state = {
    data: [],
    orderables: [
    {
      title: 'Nome',
      name: 'arquivo_nome'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    }
  ],
  filterGroups: [{
    name: 'Arquivo',
    filters: [{
        title: 'Nome',
        name: 'arquivo_nome',
        type: 'string'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at',
        type: 'datetime'
      }
    ]
  }]
  };
  
  const mutations = {
    SET_EXPIRADOS: (state, payload) => {
      state.data = payload;
    },
    ADD_EXPIRADOS: (state, payload) => {
      state.data.push(...payload);
    },
  };
  
  const actions = {
    setExpirados: (context, payload) => {
      context.commit('SET_EXPIRADOS', payload);
    },
  };
  export default {
    state,
    mutations,
    actions
  };