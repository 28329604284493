/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-tabs */
import axios from 'axios';
import Cookies from 'js-cookie';
export default function (Vue) {
  Vue.auth = {
    /**
     * Login user
     *
     * @param {string} email
     * @param {string} password
     * @param {boolean} remember
     */
    login(auth) {
      let vm = this;
      let params = {
        email: auth.email.replace(/\s/g, ''),
        password: auth.password,
        remember: auth.remember,
        token_otp: auth.token_otp,
        // forceLogin: auth.forceLogin
      };
      let promise = new Promise((resolve, reject) => {
        axios.post('/api/login', params)
          .then(response => {
            if (response.data.access_token !== undefined) {
              vm.setToken(
                response.data.access_token,
                response.data.refresh_token,
                response.data.expires_in
              );
            }

            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
      return promise;
    },
    logar(auth) {
      let vm = this;
      let params = {
        email: auth.email.replace(/\s/g, ''),
        password: auth.password,
        remember: auth.remember,
        companyId: auth.companyId,
        token_otp: auth.token_otp,
        // forceLogin: auth.forceLogin
      };
      let promise = new Promise((resolve, reject) => {
        axios.post('/api/logar', params).then(response => {
            if (response.data.access_token !== undefined) {
              vm.setToken(
                response.data.access_token,
                response.data.refresh_token,
                response.data.expires_in
              );
            }
            resolve(response);
          }).catch(error => {
            reject(error.response);
          });
      });
      return promise;
    },
    /**
     * Logout user by destroying token
     */
    logout() {
      return axios.get('/api/logout').then(response => {
        this.destroy();
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
      // this.destroy();
    },
    recoveryPassword(params) {
      let promise = new Promise((resolve, reject) => {
        axios.post('/api/password/email', params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
      return promise;
    },
    resetPassword(auth) {
      let params = {
        email: auth.email.replace(/\s/g, ''),
        password: auth.password,
        password_confirmation: auth.password_confirmation,
        token: auth.token
      };
      let promise = new Promise((resolve, reject) => {
        axios.post('/api/password/reset', params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
      return promise;
    },

    /**
     * Check if user is authenticated
     */
    /* jshint ignore:start */
    async check() {

      let access_token = await this.getToken();

      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

      return !!access_token;
    },
    getRefreshToken() {
      return Cookies.get('refresh_token');
    },
    /**
     * Refresh token
     */
    refreshToken() {
      let vm = this;
      let params = {
        refresh_token: this.getRefreshToken()
      };
      let promise = new Promise((resolve, reject) => {
        axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
        axios.post('/api/refresh-token', params)
          .then(response => {
            if (response.data.access_token !== undefined) {
              vm.setToken(
                response.data.access_token,
                response.data.refresh_token,
                response.data.expires_in
              );
            }
            toastr({
              icon: 'success',
              title: 'Sessão renovada.'
            });
            resolve(response);
          })
          .catch(error => {
            toastr({
              icon: 'error',
              title: 'Ops, você foi desconectado.'
            });
            reject(error);
          });
      });
      return promise;
    },

    /**
     * Set new access and refresh token
     *
     * @param {string} access_token
     * @param {string} refresh_token
     * @param {integer} expiration
     */
    setToken(access_token, refresh_token, expiration) {
      let expiration_miliseconds = parseInt(expiration) * 1000;
      expiration_miliseconds = Date.now() + expiration_miliseconds;
      this.storeToken(access_token, refresh_token, expiration_miliseconds);
      axios.defaults.headers.common['Authorization'] = `Bearer  ${access_token}`;
    },
    storeToken(access_token, refresh_token, expiration) {
      Cookies.set('access_token', access_token, {
        expires: 365
      });
      Cookies.set('expiration', expiration, {
        expires: 365
      });
      if (refresh_token) {
        Cookies.set('refresh_token', refresh_token, {
          expires: 7
        });
        Cookies.set('remember', false, {
          expires: 365
      });
      } else {
        Cookies.set('remember', true, {
          expires: 365
      });
    }
    },
    /**
     * Get access token or if it has expired
     * get a new one using refresh token
     */
    async getToken() {
      let access_token = Cookies.get('access_token');
      let expiration = Cookies.get('expiration');
      if (!access_token || !expiration) {
        return null;
      }
      if (Date.now() < parseInt(expiration)) {
        return access_token;
      }

      await this.refreshToken()
        .then(response => {
          console.info('Token refreshed');
          access_token = response.data.access_token;
        })
        .catch(error => {
          console.log('erro ao conectar', error);
          this.destroy();
          access_token = null;
        });

      return access_token;
    },
    /* jshint ignore:end */
    /**
     * Clear tokens from local storage
     */
    destroy() {
      setTimeout(() => {
      localStorage.clear();
      Cookies.remove('access_token');
      Cookies.remove('refresh_token');
      Cookies.remove('expiration');
      Cookies.remove('remember');
      Cookies.remove('expire_at');
      console.log('storage cleared');
      }, 500)
    }
  };
  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth;
      }
    }
  });
}