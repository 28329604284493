import axios from 'axios';

const state = {
  data: [],
  permissions: [],
  select: [],
  deleted: [],
  orderables: [{
    title: 'Descrição',
    name: 'cargo_descricao'
  },
  {
    title: 'Nome',
    name: 'cargo_nome'
  },
  {
    title: 'Cadastrado em',
    name: 'created_at'
  }
],
filterGroups: [{
  name: 'Plano',
  filters: [{
      title: 'Nome',
      name: 'cargo_nome',
      type: 'string'
    },
    {
      title: 'Descrição',
      name: 'cargo_descricao',
      type: 'string'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at',
      type: 'datetime'
    }
  ]
}]
};

const mutations = {
  SET_CARGOS: (state, payload) => {
    state.data = payload;
  },
  SET_CARGOS_DELETED: (state, payload) => {
    state.deleted = payload;
  },
  SET_CARGOS_SELECT: (state, payload) => {
    state.select = payload;
  },
  SET_CARGO_PERMISSIONS: (state, payload) => {
    state.permissions = payload;
  },
  SET_CARGO_STATUS: (state, payload) => {
    state.data[state.data.findIndex(x => x.id === payload.id)].favorite = !state.data[state.data.findIndex(x => x.id === payload.id)].favorite;
  },
};

const actions = {
  setCargos: (context, payload) => {
    if (!payload.id) {
      context.commit('SET_CARGOS', payload);
    } else {
      context.commit('SET_CARGO_STATUS', payload);
    }
  },
  setCargosDeleted: (context, payload) => {
    context.commit('SET_CARGOS_DELETED', payload);
  },
  storeCargo: (context, payload) => {
    return axios.post('/api/cargo/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  updateCargo: (context, payload) => {
    return axios.put(`/api/cargo/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getCargoById: (context, payload) => {
    return axios.get(`/api/cargo/${payload}/detalhes`).then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  },
  getAllCargos: (context) => {
    axios.get('/api/cargo/select').then(response => {
      context.commit('SET_CARGOS_SELECT', response.data);
    }).catch(error => {
      return error;
    });
  },
  getCargoPermissions: () => {
      return axios.get('/api/cargo/permissoes').then(response => {
        return response.data;    
    }).catch(error => { return error; });
  },
  changeCargoPermission: (context, payload) => {
    return axios.put('/api/cargo/permission', payload).then(response => {
      return response.data;
    }).catch(error => { return error; });
  }
};
export default {
  state,
  mutations,
  actions
};
