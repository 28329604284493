/* jshint ignore:start */
const routes = [{
    path: '/login',
    component: () => import('./views/home'),
    name: 'login',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/confirmar-email/:tokenId',
    component: () => import('./views/home/index'),
    name: 'confirmEmail',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/usuario/vincular/:tokenId',
    component: () => import('./views/home/index'),
    name: 'confirmarVinculo',
    meta: {
      //requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/usuario/registrar/:tokenId',
    component: () => import('./views/home/index'),
    name: 'confirmarCadastroVinculo',
    meta: {
      //requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/conta/cadastrar',
    component: () => import('./views/home/index'),
    name: 'cadastroSignatario',
    meta: {
      //requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/usuario/cadastrar/:tokenId',
    component: () => import('./views/home/index'),
    name: 'confirmarCadastro',
    meta: {
      //requiresAuth: false,
      ignoreAuth: true,
    },
  },
  {
    path: '/',
    component: () => import('./views/home'),
    name: 'loginIndex',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/funcionalidades',
    component: () => import('./views/home/components/funcionalidades'),
    name: 'funcionalidades',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    path: '/nossos-planos',
    component: () => import('./views/home/components/planos'),
    name: 'nossos-planos',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    path: '/contato',
    component: () => import('./views/home/components/contato'),
    name: 'contato',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    path: '/dicas',
    component: () => import('./views/home/components/dicas'),
    name: 'dicas',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    path: '/confirmar-plano/:tokenId',
    component: () => import('./views/auth/confirmar-plano'),
    name: 'confirmar-plano',
    meta: {
      requiresAuth: false,
      params: true
    }
  },
  {
    path: '/consulta-publica',
    component: () => import('./views/public/validadorDocumento'),
    name: 'validadorDocumento',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    path: '/assinar-documento/:id',
    component: () => import('./views/public/analisarDocumento'),
    name: 'assinarDocumento',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    path: '/validador',
    component: () => import('./views/public/documentoValidado'),
    name: 'documentoValidado',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    path: '/conta/cadastrar',
    component: () => import('./views/home'),
    name: 'cadastrarConta',
    meta: {
      requiresAuth: false,
      ignoreAuth: true,
    }
  },
  {
    // not found handler
    path: '*',
    component: () =>
      import('./views/home'),
    name: '404_public',
    meta: {
      title: '404',
      noCache: false,
      autoClose: true
    }
  },
  {
    path: '/password/recovery',
    component: () => import('./views/home'),
    name: 'recoveryPassword',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/api/password/reset/:token',
    component: () => import('./views/home'),
    name: 'resetPassword',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/cadastrar-usuario/:token',
    component: () => import('./views/home'),
    name: 'storeUser',
    meta: {
      requiresAuth: false
    }
  },
  
  {
    path: '/home',
    component: () => import('./views/MasterPage'),
    meta: {
      requiresAuth: true
    },
    children: [{
        path: '/home',
        component: () =>
          import('./views/dashboard/index.vue'),
        name: 'cofre',
        meta: {
          title: 'cofre',
          noCache: true,
          autoClose: false,
          onlyUser: true
        }
      },
      {
        path: '/inbox',
        component: () =>
          import('./views/inbox/inbox'),
        name: 'inbox',
        meta: {
          title: 'inbox',
          noCache: true,
          autoClose: false
        }
      },
      {
        path: '/dashboard',
        component: () =>
          import('./views/dashboard/dashboard'),
        name: 'dashboard',
        meta: {
          title: 'dashboard',
          noCache: true,
          autoClose: false,
          onlyUser: true
        }
      },
      {
        path: '/historico',
        component: () =>
          import('./views/inbox/history'),
        name: 'history',
        meta: {
          title: 'history',
          noCache: true,
          autoClose: false
        }
      },
      {
        path: '/pendentes',
        component: () =>
          import('./views/inbox/pendentes'),
        name: 'pendentes',
        meta: {
          title: 'pendentes',
          noCache: true,
          autoClose: false
        }
      },
      {
        path: '/finalizados',
        component: () =>
          import('./views/inbox/finalizados'),
        name: 'finalizados',
        meta: {
          title: 'finalizados',
          noCache: true,
          autoClose: false
        }
      },
      {
        path: '/expirados',
        component: () =>
          import('./views/inbox/expirados'),
        name: 'expirados',
        meta: {
          title: 'expirados',
          noCache: true,
          autoClose: false
        }
      },
      {
        path: '/auditoria',
        component: () =>
          import('./views/auditoria/index'),
        name: 'auditoria',
        meta: {
          title: 'auditoria',
          noCache: true,
          autoClose: false
        }
      },
      {
        path: '/parametros',
        component: () =>
          import('./views/parametros/index'),
        name: 'parametros',
        meta: {
          title: 'parametros',
          admin: false,
          noCache: true,
          autoClose: false
        }
      },
      // Empresas
      {
        path: '/empresas',
        component: () =>
          import('./views/empresa/index.vue'),
        name: 'empresas',
        meta: {
          title: 'companies',
          noCache: true,
          autoClose: false
        }
      },
      {
        path: '/empresa/:id/plano',
        component: () =>
          import('./views/empresa/components/empresa-plano'),
        name: 'empresaPlano',
        meta: {
          title: 'empresaPlano',
          noCache: true,
          admin: false,
          autoClose: true
        }
      },
      {
        path: '/empresa/:id/editar',
        component: () =>
          import('./views/empresa/form.vue'),
        name: 'empresaEdit',
        meta: {
          title: 'companyEdit',
          noCache: true,
          autoClose: true
        }
      },
      {
        path: '/empresa/cadastrar',
        component: () =>
          import('./views/empresa/form.vue'),
        name: 'empresaCreate',
        meta: {
          title: 'companyCreate',
          noCache: true,
          admin: true,
          autoClose: true
        }
      },
      {
        path: '/profile',
        component: () =>
          import('./views/users/profile.vue'),
        name: 'profile',
        meta: {
          title: 'profile',
          noCache: true
        }
      },
      // Cargos
      {
        path: '/cargos',
        component: () =>
          import('./views/cargos/index.vue'),
        name: 'cargos',
        meta: {
          title: 'posts',
          noCache: true
        }
      },
      {
        path: '/cargo/:id/editar',
        component: () =>
          import('./views/cargos/formulario.vue'),
        name: 'cargoEdit',
        meta: {
          title: 'postEdit',
          noCache: true,
          autoClose: true
        }
      },
      {
        path: '/cargo/cadastrar',
        component: () =>
          import('./views/cargos/formulario.vue'),
        name: 'cargoCreate',
        meta: {
          title: 'postCreate',
          noCache: true,
          autoClose: true
        }
      },
      {
        path: '/cargos/excluidos',
        component: () =>
          import('./views/cargos/deleted.vue'),
        name: 'cargoDeleted',
        meta: {
          title: 'postsDeleted',
          noCache: true
        }
      },
      // faturamento
      {
        path: '/docx-faturamento',
        component: () =>
          import('./views/planos/faturamento'),
        name: 'configFaturamento',
        meta: {
          title: 'configFaturamento',
          noCache: true,
          admin: true
        }
      },
      // termos
      {
        path: '/termos',
        component: () =>
          import('./views/termos/index.vue'),
        name: 'termos',
        meta: {
          title: 'termos',
          noCache: true,
          admin: true
        }
      },
      {
        path: '/termo/:id/editar',
        component: () =>
          import('./views/termos/formulario.vue'),
        name: 'termoEdit',
        meta: {
          title: 'termoEdit',
          noCache: true,
          autoClose: true,
          admin: true
        }
      },
      {
        path: '/termo/cadastrar',
        component: () =>
          import('./views/termos/formulario.vue'),
        name: 'termoCreate',
        meta: {
          title: 'termoCreate',
          noCache: true,
          autoClose: true,
          admin: true
        }
      },
      // precadastro
      {
        path: '/pre-cadastros',
        component: () =>
          import('./views/pre-cadastros/index'),
        name: 'preCadastro',
        meta: {
          title: 'preCadastro',
          noCache: true,
          admin: true
        }
      },
      // plano validade
      {
        path: '/planos-periodo',
        component: () =>
          import('./views/plano-validade/index'),
        name: 'planosValidade',
        meta: {
          title: 'planoPeriodoIndex',
          noCache: true,
          admin: true
        }
      },
      {
        path: '/plano-periodo/cadastrar',
        component: () =>
          import('./views/plano-validade/formulario'),
        name: 'plano-periodoCreate',
        meta: {
          title: 'planoPeriodoCreate',
          admin: true,
          autoClose: true,
          noCache: true,
        }
      },
      {
        path: '/plano-periodo/:id/editar',
        component: () =>
          import('./views/plano-validade/formulario'),
        name: 'plano-periodoEdit',
        meta: {
          title: 'planoPeriodoEdit',
          admin: true,
          autoClose: true,
          noCache: true,
        }
      },
      // plano modulo
      {
        path: '/planos-modulo',
        component: () =>
          import('./views/plano-modulo/index'),
        name: 'planosModulo',
        meta: {
          title: 'planoModuloIndex',
          noCache: true,
          admin: true
        }
      },
      {
        path: '/plano-modulo/cadastrar',
        component: () =>
          import('./views/plano-modulo/formulario'),
        name: 'plano-moduloCreate',
        meta: {
          title: 'planoModuloCreate',
          admin: true,
          autoClose: true,
          noCache: true,
        }
      },
      {
        path: '/planoModulo/:id/editar',
        component: () =>
          import('./views/plano-modulo/formulario'),
        name: 'planoModuloEdit',
        meta: {
          title: 'planoModuloEdit',
          admin: true,
          autoClose: true,
          noCache: true,
        }
      },
      // planos
      {
        path: '/planos',
        component: () =>
          import('./views/planos/index.vue'),
        name: 'planos',
        meta: {
          title: 'planos',
          noCache: true,
          admin: true
        }
      },
      {
        path: '/plano/:id/editar',
        component: () =>
          import('./views/planos/formulario.vue'),
        name: 'planoEdit',
        meta: {
          title: 'planoEdit',
          noCache: true,
          autoClose: true,
          admin: true
        }
      },
      {
        path: '/plano/cadastrar',
        component: () =>
          import('./views/planos/formulario.vue'),
        name: 'planoCreate',
        meta: {
          title: 'planoCreate',
          noCache: true,
          autoClose: true,
          admin: true
        }
      },
      {
        path: '/planos/excluidos',
        component: () =>
          import('./views/planos/deleted.vue'),
        name: 'planoDeleted',
        meta: {
          title: 'planoDeleted',
          noCache: true,
          admin: true
        }
      },
      // niveis
      {
        path: '/niveis',
        component: () =>
          import('./views/niveis/index.vue'),
        name: 'niveis',
        meta: {
          title: 'niveis',
          noCache: true
        }
      },
      {
        path: '/nivel/:id/editar',
        component: () =>
          import('./views/niveis/formulario.vue'),
        name: 'nivelEdit',
        meta: {
          title: 'nivelEdit',
          noCache: true,
          autoClose: true
        }
      },
      {
        path: '/nivel/cadastrar',
        component: () =>
          import('./views/niveis/formulario.vue'),
        name: 'nivelCreate',
        meta: {
          title: 'nivelCreate',
          noCache: true,
          autoClose: true
        }
      },
      // Usuários
      {
        path: '/usuarios',
        component: () =>
          import('./views/users/index.vue'),
        name: 'usuarios',
        meta: {
          title: 'users',
          noCache: false
        }
      },
      {
        path: '/users/:id/editar',
        component: () =>
          import('./views/users/formulario.vue'),
        name: 'userEdit',
        meta: {
          title: 'userEdit',
          noCache: false,
          autoClose: true
        }
      },
      {
        path: '/usuario/:id/permissoes',
        component: () =>
          import('./views/users/permissions.vue'),
        name: 'userPermissions',
        meta: {
          title: 'userPermissions',
          noCache: false,
          autoClose: true
        }
      },
      {
        path: '/users/cadastrar',
        component: () =>
          import('./views/users/formulario.vue'),
        name: 'userCreate',
        meta: {
          title: 'userCreate',
          noCache: false,
          autoClose: true
        }
      },
      {
        path: '/usuarios/excluidos',
        component: () =>
          import('./views/users/deleted.vue'),
        name: 'usersDeleted',
        meta: {
          title: 'userDeleted',
          noCache: true
        }
      },
      {
        path: '/comprar',
        component: () =>
          import('./views/loja/comprar.vue'),
        name: 'comprar',
        meta: {
          title: 'comprar',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        path: '/sms',
        component: () =>
          import('./views/sms/index.vue'),
        name: 'sms',
        meta: {
          title: 'sms',
          noCache: false,
          autoClose: true
        },
      },
      {
        path: '/experimente-gratis',
        component: () =>
          import('./views/loja/experimente-gratis.vue'),
        name: 'experimente',
        meta: {
          title: 'experimente',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        path: '/upgrade-plano',
        component: () =>
          import('./views/loja/upgrade.vue'),
        name: 'upgrade',
        meta: {
          title: 'upgrade',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        path: '/loja',
        component: () =>
          import('./views/loja/adicionais.vue'),
        name: 'loja',
        meta: {
          title: 'loja',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        // not found handler
        path: '*',
        component: () =>
          import('./views/errors/404.vue'),
        name: '404',
        meta: {
          title: '404',
          noCache: false,
          autoClose: true
        }
      },
      {
        // not found handler
        path: '/404',
        component: () =>
          import('./views/errors/404.vue'),
        name: 'notFound',
        meta: {
          title: '404',
          noCache: false,
          autoClose: true
        }
      },
      {
        // not found handler
        path: '/403',
        component: () =>
          import('./views/errors/403.vue'),
        name: '403',
        meta: {
          title: '403',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        path: '/451',
        component: () =>
          import('./views/errors/451.vue'),
        name: '451',
        meta: {
          title: '451',
          noCache: false,
          autoClose: true
        },
        props: true
      },
      {
        path: '/401',
        component: () =>
          import('./views/errors/401.vue'),
        name: '401',
        meta: {
          title: '401',
          noCache: false,
          autoClose: true
        }
      },
    ]
  }
];
export default routes;