const state = {
  data: [],
  orderables: [
  {
    title: 'Nome',
    name: 'arquivo_nome'
  },
  {
    title: 'Cadastrado em',
    name: 'created_at'
  }
],
filterGroups: [{
  name: 'Arquivo',
  filters: [{
      title: 'Nome',
      name: 'arquivo_nome',
      type: 'string'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at',
      type: 'datetime'
    }
  ]
}]
};

const mutations = {
  SET_INBOX: (state, payload) => {
    state.data = payload;
  },
  ADD_INBOX: (state, payload) => {
    state.data.push(...payload);
  },
};

const actions = {
  setInbox: (context, payload) => {
    context.commit('SET_INBOX', payload);
  },
};
export default {
  state,
  mutations,
  actions
};