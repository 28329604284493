import axios from 'axios';

const state = {
  data: [],
  select: [],
  orderables: [{
      title: 'Descrição',
      name: 'nivel_descricao'
    },
    {
      title: 'Nome',
      name: 'nivel_nome'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    }
  ],
  filterGroups: [{
    name: 'Nivel',
    filters: [{
        title: 'Nome',
        name: 'nivel_nome',
        type: 'string'
      },
      {
        title: 'Descrição',
        name: 'nivel_descricao',
        type: 'string'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at',
        type: 'datetime'
      }
    ]
  }]
};

const mutations = {
  SET_NIVEIS: (state, payload) => {
    state.data = payload;
  },
  SET_NIVEIS_DELETED: (state, payload) => {
    state.deleted = payload;
  },
  SET_NIVEIS_SELECT: (state, payload) => {
    state.select = payload;
  },
};

const actions = {
  storeNivel: (context, payload) => {
    return axios.post('/api/nivel/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  ordenarNivel: (context, payload) => {
    return axios.put('/api/nivel/ordenar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        toastr({title: 'Ops, houve um erro ao ordenar as pastas', type:'error'});
        return error.response.data;
      });
  },
  deleteNivel: (context, payload) => {
    return axios.put(`/api/nivel/${payload.id}/deletar`, payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  updateNivel: (context, payload) => {
    return axios.put(`/api/nivel/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
};
export default {
  state,
  mutations,
  actions
};