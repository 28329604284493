import axios from 'axios';

const state = {
    data: [],
    deleted: [],
    select: [],
    publicSelect: [],
    selectedPlano: {
        recorrencias: [],
    },
    adicionais: [],
    orderables: [{
            title: 'Nome',
            name: 'nome'
        },
        {
            title: 'Cadastrado em',
            name: 'created_at'
        }
    ],
    filterGroups: [{
        name: 'Plano',
        filters: [{
                title: 'Nome',
                name: 'nome',
                type: 'string'
            },
            {
                title: 'Cadastrado em',
                name: 'created_at',
                type: 'datetime'
            }
        ]
    }]
};

const mutations = {
    SET_PLANO: (state, payload) => {
        state.data = payload;
    },
    SET_PLANO_DELETED: (state, payload) => {
        state.deleted = payload;
    },
    SET_PLANO_SELECT: (state, payload) => {
        state.select = payload;
    },
    SET_PLANO_PUBLIC_SELECT: (state, payload) => {
        state.publicSelect = payload;
    },
    SET_PLANO_SELECTED: (state, payload) => {
        state.selectedPlano = payload;
    },
    SET_ADICIONAIS: (state, payload) => {
        state.adicionais = payload;
    },
};

const actions = {
    setPlanos: (context, payload) => {
        context.commit('SET_PLANO', payload);
    },
    setPlanosDeleted: (context, payload) => {
        context.commit('SET_PLANO_DELETED', payload);
    },
    storePlano: (context, payload) => {
        return axios.post('/api/plano/cadastrar', payload)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    updatePlano: (context, payload) => {
        return axios.put(`/api/plano/${payload.id}/editar`, payload).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getPlanoById: (context, payload) => {
        return axios.get(`/api/plano/${payload}/detalhes`).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getPlanosPublico: (context, payload) => {
        return axios.get('/api/planos-disponiveis').then(response => {
            context.commit('SET_PLANO_PUBLIC_SELECT', response.data);
            context.commit('SET_PLANO_SELECTED', response.data[0]);
            return response.data;
        }).catch(error => {
            // console.log(error.response.data);
            console.log(error.response);
        });
    },
    storeCompanyAditional: (context, payload) => {
        return axios.post(`/api/empresa/adicional`, payload).then(response => {
            return response.data;
        }).catch(error => {
            console.log(error.response.data);
        });
    },
    destroyCompanyAditional: (context, payload) => {
        return axios.delete(`/api/empresa/adicional/${payload}/deletar`).then(response => {
            return response.data;
        }).catch(error => {
            console.log(error.response.data);
        });
    },
};
export default {
    state,
    mutations,
    actions
};