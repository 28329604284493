import axios from 'axios';

const state = {
    data: [],
    select: [],
    orderables: [{
        title: 'Nome',
        name: 'nome'
      },
      {
        title: 'Descrição',
        name: 'descricao'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at'
      }
    ],
    filterGroups: [{
      name: 'Nome',
      filters: [{
          title: 'Nome',
          name: 'nome',
          type: 'string'
        },
        {
          title: 'Descrição',
          name: 'descricao',
          type: 'string'
        },
      ]
    }]    
};

const mutations = {
    SET_PLANO_MODULO: (state, payload) => {
        state.data = payload;
    },
    SET_PLANO_MODULO_SELECT: (state, payload) => {
        state.select = payload;
    },
};

const actions = {
    setPlanoModulo: (context, payload) => {
        context.commit('SET_PLANO_MODULO', payload);
    },
    storePlanoModulo: (context, payload) => {
        return axios.post('/api/plano-modulo/cadastrar', payload)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    updatePlanoModulo: (context, payload) => {
        return axios.put(`/api/plano-modulo/${payload.id}/editar`, payload).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    },
    getPlanoModuloById: (context, payload) => {
        return axios.get(`/api/plano-modulo/${payload}/detalhes`).then(response => {
            return response;
        }).catch(error => {
            return error;
        });
    },
    getAllPlanoModulo: (context, payload) => {
        axios.get('/api/planos-modulo/select').then(response => {
            context.commit('SET_PLANO_MODULO_SELECT', response.data);
        }).catch(error => {
            return error;
        });
    },
};
export default {
    state,
    mutations,
    actions
};