import axios from 'axios';

const state = {
  data: [],
  select: [],
  deleted: [],
  orderables: [{
      title: 'Nome',
      name: 'arquivo_nome'
    },
    {
      title: 'Cadastrado em',
      name: 'created_at'
    }
  ],
  filterGroups: [{
    name: 'Arquivo',
    filters: [{
        title: 'Nome',
        name: 'arquivo_nome',
        type: 'string'
      },
      {
        title: 'Assinado',
        name: 'assinaturas.assinado',
        type: 'boolean'
      },
      {
        title: 'Cadastrado em',
        name: 'created_at',
        type: 'datetime'
      }
    ]
  }]
};

const mutations = {
  SET_ARQUIVOS: (state, payload) => {
    state.data = payload;
  },
  ADD_ARQUIVOS: (state, payload) => {
    state.data.push(...payload);
  },
  SET_ARQUIVOS_DELETED: (state, payload) => {
    state.deleted = payload;
  },
  SET_ARQUIVOS_SELECT: (state, payload) => {
    state.select = payload;
  },
};

const actions = {
  setArquivos: (context, payload) => {
    context.commit('SET_ARQUIVOS', payload);
  },
  setArquivosDeleted: (context, payload) => {
    context.commit('SET_ARQUIVOS_DELETED', payload);
  },
  getFileSignature: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/assinatura/${payload}/analisar`)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  resendTokenOtp: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/assinatura/${payload}/reenviar-token`)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    });
    return promise;
  },
  storeArquivo: (context, payload) => {
    return axios.post('/api/arquivo/cadastrar', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });
  },
  updateArquivo: (context, payload) => {
    return axios.put(`/api/arquivo/${payload.id}/editar`, payload).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
  getArquivoById: (context, payload) => {
    return axios.get(`/api/arquivo/${payload}/detalhes`).then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  },
  getAllArquivos: (context) => {
    axios.get('/api/arquivos/all').then(response => {
      context.commit('SET_ARQUIVOS_SELECT', response.data);
    }).catch(error => {
      return error;
    });
  },
  getArquivoPermissions: () => {
    return axios.get('/api/arquivo/permissoes').then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  },
  changeArquivoPermission: (context, payload) => {
    return axios.put('/api/arquivo/permission', payload).then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  },
  downloadFile: (context, payload) => {
    return axios({
      url: `/api/file/${payload.id}/stream`,
      method: 'GET',
      responseType: 'blob' // important
    }).then((response) => {
      //console.log(response);
      /*    console.log(response)
         return; */
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${payload.arquivo_nome}.pdf`);
      document.body.appendChild(link);
      link.click();
      return null;
    });
  },
  downloadFileLote: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios({
        url: '/api/files/download',
        method: 'POST',
        data: payload,
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'arquivos.zip');
        document.body.appendChild(link);
        link.click();
        resolve(true)
      }).catch(error => {
        reject(false)
      });
    });
    return promise;
  },
  downloadFileWithLogLote: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios({
        url: '/api/files/download-log',
        method: 'POST',
        data: payload,
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'arquivos-log.zip');
        document.body.appendChild(link);
        link.click();
        resolve(true)
      }).catch(error => {
        reject(false)
      });
    });
    return promise;
  },
  downloadFileWithLog: (context, payload) => {
    return axios({
      url: `/api/file/${payload.id}/stream-log`,
      method: 'GET',
      responseType: 'blob' // important
    }).then((response) => {
      //console.log(response);
      /*    console.log(response)
         return; */
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${payload.arquivo_nome}-comLog.pdf`);
      document.body.appendChild(link);
      link.click();
      return null;
    }).catch(error => {
      console.log(error)
      toastr({
        icon: 'error',
        title: 'Erro ao obter arquivo com log!'
    });
    })
  },
  requestSignatures: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/arquivo/${payload.file}/assinaturas`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  handleFileOrder: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.patch(`/api/arquivo/${payload}/ordem`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  updateOrderSignature: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put(`/api/arquivo/${payload.file}/alterar-assinatura`, payload).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  sendSignature: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/arquivo/${payload.file}/assinatura`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  sendSignatureWhatsApp: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/arquivo/${payload.file}/whatsapp`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  autoSignFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/arquivo/${payload.file}/auto-assinar`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  signFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/arquivo/${payload.file}/assinar-cert`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  signFilePublic: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/assinatura/${payload.signature}/assinar-cert-publico`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },

  signWithoutCert: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/arquivo/${payload.id}/assinar`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  signWithoutCertPublic: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/api/arquivo/${payload.signature}/assinar-publico`, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  prepareSignFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/arquivo/${payload}/preparar`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  prepareAutoSignFile: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/arquivo/${payload}/preparar?auto_assinar=1`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  prepareSignFilePublic: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`/api/assinatura/${payload}/preparar-publico`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
    return promise;
  },
  getVerificacaoPublica: (context, payload) => {
    return axios.get(`/api/arquivo/${payload}/consulta-publica`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
  },
};
export default {
  state,
  mutations,
  actions
};