/* eslint-disable brace-style */
/* eslint-disable block-spacing */
const state = {
  visitedViews: [],
  cachedViews: []
};
const mutations = {
  ADD_VISITED_VIEWS: (state, view) => {
    if (state.visitedViews.some(v => v.path === view.path))
    // || state.visitedViews.some(v => v.title === view.meta.title)
    {return;}
    let object = {};
    object.title = view.meta.title;
    object.path = view.path;
    object.autoClose = view.meta.autoClose ? view.meta.autoClose : false;
    Object.assign({}, view, {
      title: view.meta.title
    });
    state.visitedViews.push(object);
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }
  },
  DEL_VISITED_VIEWS: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1);
        break;
      }
    }
    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i);
        state.cachedViews.splice(index, 1);
        break;
      }
    }
  },
  DEL_OTHERS_VIEWS: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews = state.visitedViews.slice(i, i + 1);
        break;
      }
    }
    for (const i of state.cachedViews) {
      if (i === view.name) {
        const index = state.cachedViews.indexOf(i);
        state.cachedViews = state.cachedViews.slice(index, i + 1);
        break;
      }
    }
  },
  DEL_ALL_VIEWS: (state) => {
    state.visitedViews = [];
    state.cachedViews = [];
  },
  DEL_CACHED_VIEW: (state, view) => {
    for (const i of state.cachedViews) {
      if (i === view.title) {
        const index = state.cachedViews.indexOf(i);
        console.log(index);
        state.cachedViews.splice(index, 1);
        break;
      }
    }
  },
};
const actions = {
  addVisitedViews ({
    commit
  }, view) {
    commit('ADD_VISITED_VIEWS', view);
  },
  delVisitedViews ({
    commit,
    state
  }, view) {
    return new Promise((resolve) => {
      commit('DEL_VISITED_VIEWS', view);
      resolve([...state.visitedViews]);
    });
  },
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view);
      resolve([...state.cachedViews]);
    });
  },
  delOthersViews ({
    commit,
    state
  }, view) {
    return new Promise((resolve) => {
      commit('DEL_OTHERS_VIEWS', view);
      resolve([...state.visitedViews]);
    });
  },
  delAllViews ({
    commit,
    state
  }) {
    return new Promise((resolve) => {
      commit('DEL_ALL_VIEWS');
      resolve([...state.visitedViews]);
    });
  }
};

export default {
  state,
  mutations,
  actions
};
