import axios from 'axios';

const actions = {
  getParametros: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get('/api/configuracoes')
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    })
    return promise;
  },
  setParametros: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post('/api/configuracoes', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data);
        });
    })
    return promise;
  },
};

export default {
  actions
};